document.addEventListener('DOMContentLoaded', () => {

  /* PHONE VALIDATION */
  function phoneValidation(event) {
    let string = event.target.value;
    let value = string.slice(2).replace(/\D/g, '');
    event.target.value = '';
    let arr = value.split('');
    let rdyArr = ['+7'];
    let result = '';
    arr.forEach(function (digit, index) {
      if (index == 0) {
        rdyArr.push(' (');
      }
      if (index == 3) {
        rdyArr.push(') ');
      }
      if (index == 6 || index == 8) {
        rdyArr.push(' ');
      }
      if (index > 9) {
        return;
      }
      rdyArr.push(digit);
    })
    rdyArr.forEach(function (str) {
      result += str;
    })
    event.target.value = result;
  }
  function numberFilter (event) {
    let string = event.target.value;
    let value = string.replace(/\D/g, '');
    event.target.value = '';
    let arr = value.split('');
    let rdyArr = [''];
    let result = '';
    arr.forEach(function (digit, index) {
      
      rdyArr.push(digit);
    })
    rdyArr.forEach(function (str) {
      result += str;
    })
    event.target.value = result;
  }

  $('.selection-table-input input').on('change input focus', numberFilter)

  $('.modal form').on('submit', function (event) {
    if ($('input[type="checkbox"]:checked').length <= 0) {
      event.preventDefault();
      $('.private-policy').addClass('is-invalid');
    }
  })
  $('.private-policy input').on('change', function() {
    $('.private-policy').removeClass('is-invalid');
  })

  const phoneInputs = document.querySelectorAll('input[type="tel"]');
  if (phoneInputs) {
    phoneInputs.forEach(phone => {
      phone.addEventListener('input', phoneValidation);
      phone.addEventListener('focus', phoneValidation);
      phone.addEventListener('change', phoneValidation);
    })
  }

})

$(function () {

  $('body').on('keydown', function(e) {
    if(e.which == 27) {
      $('.modal').fadeOut(300);
      $('.modal-overlay').fadeOut(300);
    }
  })

  $('.btn-order').on('click', function(e) {
    e.preventDefault();
    $('.modal-order').fadeIn(300);
    $('.modal-overlay').fadeIn(300);
  })

  $('.modal-close').on('click', function(e) {
    $(this).parents('.modal').fadeOut(300);
    $('.modal-overlay').fadeOut(300);
  })

  $('.modal-overlay').on('click', function(e) {
    $('.modal').fadeOut(300);
    $('.modal-overlay').fadeOut(300);
  })

  $('.hero-button').click(function (event) {
    event.preventDefault();
    const dataTarget = $(this).get(0).dataset.section;
    $("html, body").animate({
      scrollTop: $('' + dataTarget).offset().top - 40
    }, 500);
  })

  $('input[type="tel"]').on('input focus change', function (e) {
    $(e.target).parent().removeClass('is-invalid');
  })

  $('.close-cross').on('click', function () {
    $(this).parents('.components-list .is-active').removeClass('is-active');
  })

  const $featuresList = $('.features-list .features-item');

  let progressStep = $('.progress-track').width() / $featuresList.length;
  $('.progress-bar').css('width', progressStep);

  $('.features-list').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    $('.progress-bar').css('width', (progressStep * nextSlide + progressStep) + 'px');
  });

  $('.features-list').slick({
    arrows: false,
    infinite: false,
    swipeToSlide: true,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    customPaging: function () {
      return ''
    }
  });

})
